import { IMasterClass } from '@/interfaces/masterclass';
import { IMasterclassDescription } from '@/interfaces/masterclassDescription';

const getDateAndTime = (masterclassDetails: IMasterclassDescription | IMasterClass) => {
  const utcDateTime = masterclassDetails?.masterclassTimings?.startTime;
  const localDateTime = new Date(utcDateTime);
  const date = localDateTime.toLocaleDateString(); // 'MM/DD/YYYY' format by default
  const time = localDateTime.toLocaleTimeString();

  return {
    date,
    time,
  };
};

const getInstructorNameAndCompany = (
  masterclassDetails: IMasterclassDescription | IMasterClass
) => {
  const instructors = masterclassDetails?.instructors;
  let instructorName = '';
  let instructorDescription = '';
  let pastCompanies: string[] = [];
  if (instructors) {
    instructorName = instructors[0]?.name || '';
    pastCompanies =
      instructors[0]?.instructorDetails?.pastCompanies?.map((companies: { name: string }) => {
        return companies.name as string;
      }) || [];

    instructorDescription = instructors[0]?.description || '';
  }
  return {
    instructorName,
    pastCompanies,
    instructorDescription,
  };
};

export { getDateAndTime, getInstructorNameAndCompany };
