import { useState } from 'react';

const useZoomClassErrorHandler = () => {
  const [errToaster, setErrToaster] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const handleError = (message: string) => {
    setErrMessage(message);
    setErrToaster(true);
  };

  return { errToaster, setErrToaster, errMessage, handleError };
};

export default useZoomClassErrorHandler;
