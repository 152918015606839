const ZOOM_ERR_MESSAGES = {
  STATUS_429: 'You have reached the limit of 3 sessions for this class',
  STATUS_400: 'Error: Something went wrong',
};

const ZOOM_CLASS_TYPES = {
  LIVE_CLASS: 'liveClass',
  MASTER_CLASS: 'masterClass',
};

export { ZOOM_ERR_MESSAGES, ZOOM_CLASS_TYPES };
