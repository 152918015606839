import { baseApiUrl } from '@/utils/common/env.constants';
import { VideoSession } from '@/interfaces/lectureTrack';
import { getAccessToken } from '@/components/common/video/utils';
import { del, get, patch, post } from './index';

class LectureTrackApi {
  static getSectionsWithLessons<T>({
    courseId,
    milestoneId,
  }: {
    courseId: string;
    milestoneId?: string;
  }) {
    if (milestoneId) {
      return get<T>(
        `/v2/learn/section/getAllSections/${courseId}?milestoneId=${milestoneId}`,
        undefined,
        baseApiUrl
      );
    }
    return get<T>(`/v2/learn/section/getAllSections/${courseId}`, undefined, baseApiUrl);
  }

  static getLessonDetails<T>({
    courseId,
    lessonId,
    milestoneId,
  }: {
    courseId: string;
    lessonId: string;
    milestoneId?: string;
  }) {
    const accessToken = getAccessToken();
    return get<T>(
      `/v2/learn/lesson/details/${courseId}/${lessonId}/${accessToken}${
        milestoneId ? `?milestoneId=${milestoneId}` : ''
      }`,
      undefined,
      baseApiUrl
    );
  }

  static getLectureDoubts<T>({
    courseId,
    lessonId,
    sort,
    doubtStatus,
  }: {
    courseId?: string;
    lessonId?: string;
    sort?: number;
    doubtStatus?: string;
  }) {
    let path = `/v2/learn/doubt/${courseId}/${lessonId}?limit=10&skip=0&order=1&sort=${sort}`;
    if (doubtStatus) path += `&doubtStatus=${doubtStatus}`;
    return get<T>(path, undefined, baseApiUrl);
  }

  static getLectureDoubtsReplies<T>(
    courseId: string,
    lessonId: string,
    doubtId: string | undefined
  ) {
    return get<T>(
      `/v2/learn/doubt/${courseId}/${lessonId}/${doubtId}/replies?limit=10&skip=0`,
      undefined,
      baseApiUrl
    );
  }

  static updateLikeStatusApi(courseId: string, lessonId: string, doubtId: string | undefined) {
    return patch(
      `/v2/learn/doubt/${courseId}/${lessonId}/${doubtId}/like-action`,
      undefined,
      undefined,
      baseApiUrl
    );
  }

  static createDoubtsApi<T>(
    courseId?: string,
    lessonId?: string,
    milestoneId?: string,
    data?: {
      comment?: string;
      replyOfComment?: string;
      'g-recaptcha-response'?: string;
      timeStamp?: number;
    }
  ) {
    let path = `/v2/learn/doubt/${courseId}/${lessonId}`;
    let payLoad = { ...data };
    if (!data?.replyOfComment)
      payLoad = {
        comment: data?.comment,
        'g-recaptcha-response': data?.['g-recaptcha-response'],
        timeStamp: data?.timeStamp,
      };
    if (milestoneId) path += `?milestoneId=${milestoneId}`;
    return post<T>(path, payLoad, undefined, baseApiUrl);
  }

  static deleteDoubtsApi(
    courseId: string,
    lessonId: string,
    doubtId: string | undefined,
    data: {
      comment?: string;
      replyOfComment?: string;
      'g-recaptcha-response'?: string;
      timeStamp?: number;
    }
  ) {
    return del(`/v2/learn/doubt/${courseId}/${lessonId}/${doubtId}`, data, undefined, baseApiUrl);
  }

  static reportDoubtsApi(
    courseId: string,
    lessonId: string,
    doubtId: string | undefined,
    data: any
  ) {
    return post(
      `/v2/learn/doubt/${courseId}/${lessonId}/${doubtId}/report-doubt`,
      data,
      undefined,
      baseApiUrl
    );
  }

  static changeDoubtsStatusApi(
    courseId: string,
    lessonId: string,
    doubtId: string | undefined,
    data: { unresolveText?: string; doubtStatus?: string; unresolveType?: Array<string> | string }
  ) {
    let tempObj = {};
    if (data?.doubtStatus) tempObj = { doubtStatus: data?.doubtStatus };
    if (data?.unresolveType !== 'null')
      tempObj = {
        ...tempObj,
        unresolveType: data?.unresolveType,
      };
    if (data?.unresolveText)
      tempObj = {
        ...tempObj,
        unresolveText: data?.unresolveText,
      };
    return patch(
      `/v2/learn/doubt/${courseId}/${lessonId}/${doubtId}/lesson-doubt-status`,
      tempObj,
      undefined,
      baseApiUrl
    );
  }

  static updateRecentlyAccessedLesson(
    payload: {
      lessonId: string;
      milestoneId?: string;
    },
    courseId: string
  ) {
    return patch(`/learn/progress/${courseId}/recently-accessed-lessons`, payload);
  }

  static updateCourseProgress<T>(
    payload: { lessonId: string; milestoneId?: string; sectionId: string },
    courseId: string
  ) {
    return patch<T>(`/learn/progress/${courseId}`, payload);
  }

  static async postSetReminder(courseId: string, data: { eventId: string; type: string }) {
    return post(`/v2/learn/career-path/remind-me/${courseId}`, data, undefined, baseApiUrl);
  }

  static async updateVideoSession(courseId: string, lessonId: string, payload: VideoSession) {
    return patch(
      `/v1/learn/lesson/video-session/${courseId}/lesson/${lessonId}`,
      payload,
      undefined,
      baseApiUrl
    );
  }

  static async getVideoSession<T>(courseId: string, lessonId: string) {
    return get<T>(
      `/v1/learn/lesson/video-session/${courseId}/lesson/${lessonId}`,
      undefined,
      baseApiUrl
    );
  }

  static async patchRecentlyAccesedLessonApi<T>(courseId: string, lessonId: string) {
    return patch<T>(
      `/v1/learn/analytics/lastPlayed/course/${courseId}`,
      {
        lessonId,
      },
      undefined,
      baseApiUrl
    );
  }

  static async getLessonDetailsQuiz(courseId: any, lessonId: any) {
    return get(`v1/lesson/course/${courseId}/${lessonId}`, undefined, baseApiUrl);
  }

  static async submitQuiz(courseId: any, lessonId: any, answers: any, recaptchaToken: any) {
    return post(
      `v1/learn/submission/${courseId}/quiz/${lessonId}`,
      {
        answers,
        'g-recaptcha-response': recaptchaToken,
      },
      undefined,
      baseApiUrl
    );
  }

  static async submitAssignment(courseId: any, lessonId: any, url: any, recaptchaToken: any) {
    return post(
      `v1/learn/submission/${courseId}/assignment/${lessonId}`,
      {
        url,
        'g-recaptcha-response': recaptchaToken,
      },
      undefined,
      baseApiUrl
    );
  }

  static async getZoomClassDetails<T>(courseId: string, lessonId?: string) {
    return get<T>(
      `v2/learn/lesson/zoom/${courseId}/${lessonId || courseId}`,
      undefined,
      baseApiUrl
    );
  }
}

export default LectureTrackApi;
