export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatMasterClassDate = (date: string) => {
  const jsDate = new Date(date);
  const week = daysOfWeek[jsDate.getDay()];
  const month = months[jsDate.getMonth()];
  const currentDate = jsDate.getDate();
  const year = jsDate.getFullYear();
  return `${week}, ${currentDate} ${month}, ${year}`;
};

export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${formattedMinutes}${ampm}`;
  return strTime;
};
