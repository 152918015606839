/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { Button, Card, Typography } from '@pwskills/rachnaui';
import { ArrowRedo, Calendar, Clock } from '@pwskills/rachnaui/Icons';
import Link from 'next/link';
import { getRandomId } from '@/components/common/video/utils';
import { IClassTimings, IMasterClass } from '@/interfaces/masterclass';
import { useRouter } from 'next/router';
import { formatAMPM, formatMasterClassDate } from '@/utils/common/date';
import { DOMAIN } from '@/utils/homePage/env.constants.utils';
import MasterClassApi from '@/api/masterclass';
import useZoomClass from '@/hooks/useZoomClass';
import { ZOOM_CLASS_TYPES } from '@/utils/aboutUs/zoomClass';
import useZoomClassErrorHandler from '@/hooks/useZoomClassErrorHandler';
import UserDetailsContext from '@/context/UserDetailsContext';
import moEvent from '@/utils/moEngage';
import { getDateAndTime } from '@/utils/masterclass/masterclass';
import { GAevent } from '@/utils/firebase.utils';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import { classNames } from '@pwskills/rachnaui/utils';
import SocialShareModal from '../common/SocialShareModal';
import Toaster from '../common/Toaster';

type Props = {
  smallerCards: boolean;
  learnPage: boolean;
  data: IMasterClass;
  source: string;
};

const MasterClassCard = ({ smallerCards, data, learnPage = false, source }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const router = useRouter();
  const { user, isLoggedIn } = useContext(UserDetailsContext);
  const { errToaster, setErrToaster, errMessage, handleError } = useZoomClassErrorHandler();
  const { getZoomKeysDetails } = useZoomClass({
    courseId: data._id,
    courseName: data.title,
    handleError,
    zoomClassType: ZOOM_CLASS_TYPES.MASTER_CLASS,
    masterclassSlug: data._id,
  });
  const startTime = new Date(data.masterclassTimings.startTime);
  const endTime = new Date(data.masterclassTimings.endTime);
  const currentTime = new Date();
  const utmQuery = useUTMParameters();
  const formatTime = (timings: IClassTimings) => {
    if (timings) {
      const formattedStartTime = formatAMPM(new Date(timings.startTime));
      const formattedEndTime = formatAMPM(new Date(timings.endTime));
      return `${formattedStartTime} to ${formattedEndTime}`;
    }
    return undefined;
  };

  const canJoinClass = () => {
    if (learnPage) {
      if (data.certificate) {
        if (startTime <= currentTime && endTime >= currentTime) {
          return true;
        }
        return false;
      }
      if (startTime <= currentTime && endTime >= currentTime) {
        return true;
      }
      return false;
    }
    if (data.isBought) {
      if (startTime <= currentTime && endTime >= currentTime) {
        return true;
      }
      return false;
    }
    return false;
  };

  const certificateAvailable = () => {
    if (data.certificate) {
      return true;
    }
    return false;
  };

  const sendGAEvent = (action: string) => {
    const GAEventData = {
      user_id: user?._id,
      user_type: isLoggedIn ? 'loggedin' : 'not_loggedin',
      action,
      page_name: `${learnPage ? 'learn_page' : 'masterclass_page'}`,
      page_ref: router.query.page_ref,
      source,
      category_id: data?.categoryIncludingParents[1],
      category_name: data?.categoryId?.parent?.title,
      course_id: data?._id,
      course_name: data?.title,
      course_type: 'masterclass',
      utm_source: utmQuery?.utm_source,
      utm_medium: utmQuery?.utm_medium,
      utm_campaign: utmQuery?.utm_campaign,
    };

    if (learnPage) {
      GAevent('sk_mycourse_action', GAEventData);
    } else {
      GAevent('sk_course_card_click', GAEventData);
    }
  };

  const moEngageEventTrigger = () => {
    if (router?.isReady && user?._id && isLoggedIn) {
      const moEngageData = {
        user_id: user?._id || '',
        username: `${user?.firstName} ${user?.lastName}` || '',
        phone_number: user?.phoneNumber || '',
        user_type: isLoggedIn ? 'loggedin' : 'not_loggedin',
        masterclass_name: data?.title,
        masterclass_date: getDateAndTime(data).date,
        masterclass_time: getDateAndTime(data).time,
        masterclass_joining_link: data?.liveVideoLink,
        category: data?.categoryId?.parent?.title,
        ...(user?.verified && user?.email && { email: user.email }),
      };

      moEvent('masterclass_join_now_cta', moEngageData);
    }
  };

  const handleAction = async (masterClass: IMasterClass) => {
    if (canJoinClass()) {
      // eslint-disable-next-line no-console
      if (!certificateAvailable()) {
        MasterClassApi.generateCertificate(data._id);
      }
      moEngageEventTrigger();
      sendGAEvent('join_now');
      await getZoomKeysDetails();
      return;
    }
    if (certificateAvailable()) {
      sendGAEvent('download_certificate');
      window.open(`${DOMAIN}/learn/certificate/${data.certificate.id}`);
    } else {
      sendGAEvent('register_now');
      router.push(
        `/masterclass/${masterClass.slug}?position=masterclass_card&page_ref=${
          learnPage ? 'learn_page' : 'masterclass_page'
        }&click_text=register_now`
      );
    }
  };

  const renderBtnText = (masterClass: IMasterClass) => {
    if (learnPage) {
      if (data.certificate) {
        if (startTime > currentTime) {
          return 'Registered';
        }
        if (startTime <= currentTime && endTime >= currentTime) {
          return 'Join Now';
        }
        return 'Download Certificate';
      }
      if (startTime <= currentTime && endTime >= currentTime) {
        return 'Join Now';
      }
      return 'Registered';
    }
    if (masterClass.isBought) {
      if (startTime > currentTime) {
        return 'Registered';
      }
      if (startTime <= currentTime && endTime >= currentTime) {
        return 'Join Now';
      }
    }

    if (startTime < currentTime) {
      return 'Registration Closed';
    }

    return 'Register Now';
  };

  const isRegistrationClosed = () => {
    // for learn page specific
    if (learnPage) {
      if (currentTime < startTime) {
        return true;
      }
      if (data.certificate) {
        return false;
      }
    }
    if (startTime <= currentTime && endTime >= currentTime) {
      return false;
    }
    if (startTime < currentTime) {
      return true;
    }
    return false;
  };

  return (
    <Card
      key={getRandomId()}
      className={`flex mt-5 relative !w-full !mx-3 large:!mx-6 rounded-b-[20px] rounded-t-[20px] !shadow-light !shrink-0 flex-col hover:large:shadow-medium bg-white !p-0 !m-0 ${
        smallerCards ? 'medium:max-w-[265px]' : 'medium:!w-[332px] large:!w-[411px]'
      }`}
    >
      <div className="flex flex-col flex-grow">
        <Link
          onClick={() => {
            sendGAEvent('card_click');
          }}
          href={`/masterclass/${data.slug}?position=masterclass_card&page_ref=${
            learnPage ? 'learn_page' : 'masterclass_page'
          }`}
        >
          <div
            className={`mx-auto w-full col-span-2 relative pt-4 ${smallerCards ? 'px-2' : 'px-4'}`}
          >
            <img
              src={data.img}
              alt="random-ndnd"
              className={`!w-full medium:!w-[332px] large:!w-[411px]  rounded-t-[4px] ${
                learnPage ? 'h-auto medium:!h-[130px]' : 'h-auto medium:!h-[183px]'
              }`}
              width={411}
              height={200}
            />
          </div>
        </Link>
        <div
          className={`flex flex-col justify-between gap-4 medium:gap-2 large:gap-3 flex-grow ${
            smallerCards ? 'p-2' : 'p-4'
          }`}
        >
          <Link
            href={`/masterclass/${data.slug}?position=masterclass_card&page_ref=${
              learnPage ? 'learn_page' : 'masterclass_page'
            }`}
          >
            <div className="flex flex-col justify-between gap-2 medium:gap-2">
              <div className="flex flex-row !justify-between ">
                <Typography
                  component="subheading"
                  variant="bold"
                  className="text-gray-900 line-clamp-2"
                >
                  {data?.title}
                </Typography>
                {!smallerCards && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setModalOpen(true);
                    }}
                    className="min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]  bg-gray-50 rounded-[50%] flex justify-center items-center "
                  >
                    <ArrowRedo className="text-orange-500 w-4 h-4 cursor-pointer" />
                  </button>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center !justify-between mt-4 medium:mt-2 large:mt-4">
              <div className="flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <Calendar
                    className=" text-blue-500"
                    style={{
                      width: smallerCards ? '16px' : '20px',
                      height: smallerCards ? '16px' : '20px',
                    }}
                  />
                  <Typography
                    variant="semi-bold"
                    component={`${smallerCards ? 'label' : 'body-tiny'}`}
                    className={`text-blue-500 whitespace-nowrap ml-1 ${
                      learnPage ? '' : '!text-[12px] !leading-[18px]'
                    }`}
                  >
                    {formatMasterClassDate(data?.masterclassTimings?.startTime)}
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <Clock
                  className=" text-blue-500"
                  style={{
                    width: smallerCards ? '16px' : '20px',
                    height: smallerCards ? '16px' : '20px',
                  }}
                />
                <Typography
                  component={`${smallerCards ? 'label' : 'body-tiny'}`}
                  variant="semi-bold"
                  className={`text-blue-500 whitespace-nowrap ml-1 ${
                    learnPage ? '' : '!text-[12px] !leading-[18px]'
                  }`}
                >
                  {formatTime(data?.masterclassTimings)}
                </Typography>
              </div>
            </div>
          </Link>
          <Button
            variant="tertiary"
            className={classNames(
              canJoinClass() ? 'bg-green-50 hover:bg-green-100 text-green-500' : '',
              'small:!text-[16px] large:!text-[14px]  small:!leading-[24px]  large:!leading-[22px] font-bold'
            )}
            size="small"
            onClick={() => handleAction(data)}
            disabled={isRegistrationClosed()}
          >
            {renderBtnText(data)}
          </Button>
        </div>
      </div>
      <SocialShareModal
        modelOpen={modalOpen}
        setModelOpen={setModalOpen}
        url={`${DOMAIN}/masterclass/${data.slug || ''}`}
        title={`${data.title}`}
      />
      {errToaster && (
        <Toaster open={errToaster} setOpen={setErrToaster} text={errMessage} variant="error" />
      )}
    </Card>
  );
};

export default MasterClassCard;
