import { useRouter } from 'next/router';
import LectureTrackApi from '@/api/lectureTrack';
import { IZoomKeys } from '@/interfaces/lectureTrack';
import { ZOOM_CLASS_TYPES, ZOOM_ERR_MESSAGES } from '@/utils/aboutUs/zoomClass';

interface UseZoomClassProps {
  courseId: string;
  courseName: string;
  lessonId?: string;
  milestoneId?: string;
  handleError: (message: string) => void;
  zoomClassType: string;
  masterclassSlug?: string;
}

const useZoomClass = ({
  courseId,
  courseName,
  lessonId,
  milestoneId,
  handleError,
  zoomClassType = ZOOM_CLASS_TYPES.LIVE_CLASS,
  masterclassSlug,
}: UseZoomClassProps) => {
  const router = useRouter();

  const getZoomKeysDetails = async () => {
    try {
      const { data } = await LectureTrackApi.getZoomClassDetails<{ data: IZoomKeys }>(
        courseId,
        lessonId ?? ''
      );

      if (data) {
        sessionStorage.setItem('zoomData', JSON.stringify(data));
        const milestoneParam = milestoneId ? `&milestoneId=${milestoneId}` : '';
        const lessonIdParam = lessonId ? `&lessonId=${lessonId}` : '';
        const masterclassSlugParam = masterclassSlug ? `&masterclassSlug=${masterclassSlug}` : '';
        const zoomUrl = `/learn/course/${courseName}/${courseId}/zoom?isJoinNowClicked=true${milestoneParam}${lessonIdParam}&zoomClassType=${zoomClassType}${masterclassSlugParam}`;
        router.push(zoomUrl);
      }
    } catch (err: any) {
      const errorMessage =
        ZOOM_ERR_MESSAGES[err.error as keyof typeof ZOOM_ERR_MESSAGES] ||
        err.error ||
        'An unexpected error occurred';
      handleError(errorMessage);
    }
  };

  return { getZoomKeysDetails };
};

export default useZoomClass;
