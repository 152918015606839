import { baseApiUrl } from '@/utils/common/env.constants';
import { IGenerateCertificateResponse, IMasterclassResponse } from '@/interfaces/masterclass';
import { get, post } from './index';

class MasterClassApi {
  static async getMasterClassList(params: any) {
    return get<IMasterclassResponse>(`/v2/masterclass/list/`, { params }, baseApiUrl);
  }

  static async generateCertificate(courseId: string) {
    return post<IGenerateCertificateResponse>(`/masterclass/certificate/${courseId}`);
  }
}

export default MasterClassApi;
